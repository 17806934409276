body {
  padding-top: 202px;
  font-family: Quicksand, sans-serif;
  color: #222;
  font-size: 15px;
  line-height: 21px;
  font-weight: 400;
  text-align: left;
}

h1 {
  margin-top: 0px;
  font-family: acta-display, sans-serif;
  font-size: 38px;
  line-height: 46px;
  font-weight: 400;
  text-transform: none;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: acta-display, sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h3 {
  margin-top: 0px;
  font-family: Quicksand, sans-serif;
  color: #646464;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Oswald, sans-serif;
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-bottom: 20px;
  font-family: Quicksand, sans-serif;
  font-weight: 300;
}

a {
  background-color: transparent;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  font-family: Quicksand, sans-serif;
  color: #999;
  text-decoration: none;
}

a:hover {
  color: #222;
  text-decoration: none;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 20px;
}

label {
  float: left;
  font-weight: 400;
}

.nav {
  border-top: 1px solid rgba(17, 17, 17, 0.08);
  background-color: #fff;
  color: #fff;
}

.nav.top {
  background-color: #eee;
  text-align: center;
}

.nav-link {
  margin-top: 0px;
  padding: 22px 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #222;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #7fc6d0;
  text-decoration: none;
}

.nav-link.w--current {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Quicksand, sans-serif;
  color: #222;
  font-weight: 500;
  text-align: center;
}

.nav-link.nav-dropdown {
  z-index: 10;
  padding-right: 30px;
  padding-left: 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: Quicksand, sans-serif;
  font-size: 13px;
  font-weight: 600;
}

.nav-link.nav-dropdown:hover {
  border: 1px none #000;
  color: #7fc6d0;
}

.nav-link.nav-dropdown.w--open {
  position: relative;
  color: #222;
}

.nav-link.top {
  padding: 9px 10px;
  color: hsla(0, 0%, 40%, 0.6);
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: none;
}

.nav-link.top:hover {
  color: #666;
}

.nav-link.top.w--current {
  background-color: transparent;
  color: #666;
}

.nav-link.top.pull-right {
  display: inline-block;
}

.nav-link.top.pull-right.show-mobile {
  display: none;
}

.nav-link._2 {
  width: 50px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}

.nav-link.account-button {
  margin-top: 20px;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  color: #565656;
  text-transform: none;
}

.nav-link.account-button:hover {
  background-color: #565656;
  color: #fff;
}

.nav-link.account-button.w--current {
  background-color: transparent;
  color: #666;
}

.nav-link.account-button.last {
  padding-right: 0px;
}

.nav-link.account-button.last._2 {
  border-style: none;
}

.nav-link.account-button.last._2:hover {
  background-color: transparent;
  color: #222;
}

.nav-link.account-button.cart {
  margin-right: 0px;
  padding-right: 0px;
  border-style: none;
}

.nav-link.account-button.cart:hover {
  background-color: transparent;
  color: #565656;
}

.nav-link.phone {
  position: static;
  display: block;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  float: none;
  clear: none;
  color: #222;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}

.nav-link.home.w--current {
  display: none;
}

.nav-menu {
  display: block;
  float: none;
  text-align: center;
}

.nav-menu.top {
  display: block;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.input2 {
  position: relative;
  height: 40px;
  padding: 0px 14px;
  border: 1px none #666;
  border-radius: 0px;
  background-color: #fff;
}

.login-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 48px;
  height: 44px;
  padding: 0px;
  float: right;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
  font-family: 'Corin design', sans-serif;
  color: #aaa;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}

.login-btn:hover {
  border: 1px none #666;
  background-color: #565656;
  opacity: 1;
  color: #fff;
}

.slider {
  overflow: hidden;
  height: 70vh;
  max-height: 700px;
  max-width: 100%;
  min-height: 480px;
  background-color: transparent;
}

.section {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #fff;
  font-family: Quicksand, sans-serif;
}

.section.tint {
  background-color: #f0f0f0;
}

.section.cta {
  position: relative;
  overflow: hidden;
  height: 66vh;
  max-height: 700px;
  min-height: 400px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  text-align: center;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
}

.section.blog {
  padding-right: 20px;
  padding-left: 20px;
}

.section.social {
  display: none;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
}

.section.testimonials {
  display: none;
  overflow: hidden;
  color: hsla(0, 0%, 100%, 0.7);
}

.section.news.hide {
  display: none;
}

.section.top-categories {
  padding-bottom: 0px;
}

.section.products {
  padding-top: 0px;
}

.section.products.hide {
  display: none;
}

.section.products.gray {
  background-color: #f5f5f5;
}

.section.grey {
  background-color: #f5f5f5;
}

.section.grey._2 {
  padding-bottom: 0px;
}

.section.grey.hide {
  display: none;
}

.section.collection-description {
  padding-bottom: 10px;
}

.section._1 {
  padding-top: 20px;
}

.mid-footer {
  padding-top: 60px;
  padding-bottom: 40px;
  background-image: none;
  font-size: 14px;
  font-weight: 300;
}

.pull-right {
  margin-bottom: 0px;
  float: right;
}

.pull-right.w--current {
  display: inline-block;
  float: right;
}

.copyright {
  float: left;
  clear: both;
  font-family: Quicksand, sans-serif;
  color: #313030;
  text-align: center;
}

.header {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 9000;
  box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.2);
  font-weight: 300;
}

.brand {
  position: relative;
  display: inline-block;
  padding-bottom: 0px;
  float: none;
}

.brand.w--current {
  position: static;
  display: inline-block;
  width: 300px;
  height: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  float: none;
  color: #cbb682;
}

.dropdown-link {
  width: 100%;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 15px;
  line-height: 21px;
}

.dropdown-link:hover {
  color: #fff;
  text-decoration: none;
}

.dropdown-link.w--current {
  color: #fff;
}

.dropdown-list {
  background-color: #4aa6dd;
}

.dropdown-list.w--open {
  background-color: rgba(68, 68, 68, 0.9);
}

.banner-text-small {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
}

.banner-text-large {
  margin-bottom: 12px;
  font-family: Rhesmanisa, sans-serif;
  font-size: 100px;
  line-height: 140px;
  font-weight: 400;
  letter-spacing: 6px;
  text-decoration: none;
  text-transform: none;
}

.banner-text-large.brand-title {
  margin-bottom: 0px;
  color: #646464;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 8px;
}

.banner-text-large.brand-title._1 {
  margin-bottom: 10px;
  font-size: 50px;
  line-height: 80px;
}

.mini-slider {
  overflow: hidden;
  height: auto;
  padding-right: 5%;
  padding-left: 5%;
  background-color: transparent;
  opacity: 0.6;
}

.arrow {
  color: #fff;
  font-size: 35px;
}

.arrow.miniarrow {
  color: #8d8d8d;
}

.arrow.miniarrow {
  color: #c6c6c6;
}

.arrow.miniarrow.right-arrow {
  display: block;
}

.mini-slider-img {
  width: 25%;
  padding: 50px 15px;
}

.mask {
  text-align: center;
}

.mega-menu {
  display: none;
  width: 100%;
  margin-top: 15px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #666;
}

.form-col {
  padding-right: 10px;
}

.input {
  width: 100%;
  min-height: 44px;
  border: 1px solid #ddd;
  -webkit-transition: border 600ms ease;
  transition: border 600ms ease;
}

.input:hover {
  border-color: #666;
}

.input:focus {
  border-color: #666;
}

.input.inline {
  margin-bottom: 0px;
}

.input.inline:hover {
  border-color: #222;
}

.input.inline:focus {
  border-color: #222;
}

.textarea {
  min-height: 98px;
  border: 1px solid #ddd;
}

.textarea:hover {
  border-color: #666;
}

.textarea:focus {
  border-color: #666;
}

.map {
  width: auto;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.list-item {
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 21px;
  font-weight: 400;
}

.list {
  margin-top: 15px;
}

.blogpost-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  clear: both;
}

.blog-element-wrapper {
  margin-bottom: 30px;
}

.tag {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 10px 20px;
  border: 1px solid #666;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
  color: #666;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

.tag:hover {
  border-color: transparent;
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

.tag.w--current {
  font-size: 14px;
  letter-spacing: 1px;
}

.blog-social-wrapper {
  margin-top: 45px;
}

.blog-social {
  display: inline-block;
  margin-right: 10px;
  padding-right: 0px;
  float: left;
}

.fontello {
  top: 0px;
  right: 0px;
  display: inline;
  font-family: 'Corin design', sans-serif;
  color: #7fc6d0;
  font-weight: 400;
}

.fontello.fontello-cart {
  font-family: 'Corin design', sans-serif;
  font-size: 18px;
  line-height: 0px;
}

.fontello._2 {
  font-size: 18px;
}

.slide1 {
  background-image: none;
  background-position: 50% 0px;
  background-size: cover;
}

.slide {
    background-image: none;
    background-position: 0px 0px;
    background-size: 125px;
}

    .slide.slide1 {
        background-image: none;
    }

    .slide.slide2 {
        position: relative;
        overflow: hidden;
        background-image: none;
        background-size: 125px;
    }

        .slide.slide2.slide-img2 {
            background-image: none;
            background-size: cover;
            background-repeat: no-repeat;
        }

    .slide._2 {
        background-image: none;
        background-position: 0% 0%;
        background-size: cover;
    }

    .slide._3 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .slide._4 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

.about-content-wrapper {
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  text-align: center;
}

.productwrapper {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 10px 20px;
  background-color: #fff;
  box-shadow: 0 8px 20px 0 rgba(78, 78, 78, 0.1);
  -webkit-transition: color 500ms ease, opacity 500ms ease, box-shadow 900ms ease, -webkit-transform 600ms ease;
  transition: color 500ms ease, opacity 500ms ease, box-shadow 900ms ease, -webkit-transform 600ms ease;
  transition: color 500ms ease, opacity 500ms ease, transform 600ms ease, box-shadow 900ms ease;
  transition: color 500ms ease, opacity 500ms ease, transform 600ms ease, box-shadow 900ms ease, -webkit-transform 600ms ease;
  color: #666;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
}

.productwrapper:hover {
  box-shadow: 0 6px 20px 4px rgba(26, 26, 26, 0.25);
  -webkit-transform: translate(0px, -12px);
  -ms-transform: translate(0px, -12px);
  transform: translate(0px, -12px);
  color: #666;
  text-decoration: none;
}

.product-image {
  min-width: 100%;
}

.product-name {
  margin-top: 10px;
  margin-bottom: 7px;
  font-family: acta-display, sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.product-price {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
}

.dropdownlist.w--open {
  left: -342px;
  width: 940px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: rgba(12, 12, 12, 0.9);
}

.megalink {
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: #666;
  font-size: 14px;
}

.megalink:hover {
  color: #fff;
  text-decoration: none;
}

.megalink.w--current {
  color: #fff;
}

.header-fields {
  width: 50%;
  padding-top: 10px;
  float: right;
}

.nav-item {
  display: none;
  padding: 12px 10px;
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 13px;
}

.button {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: 1px solid #222;
  background-color: transparent;
  box-shadow: none;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
  color: #565656;
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: none;
}

.button:hover {
  border-color: #111;
  background-color: #565656;
  color: #fff;
  text-decoration: none;
}

.button._2 {
  border: 1px solid transparent;
  box-shadow: none;
  color: #fff;
  font-weight: 400;
  text-shadow: none;
}

.button._2:hover {
  border-color: #fff;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  text-shadow: none;
}

.button.footer {
  margin-bottom: 0px;
  padding: 10px 18px;
  border-color: hsla(0, 0%, 100%, 0.6);
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 10px;
  line-height: 18px;
}

.button.footer:hover {
  background-color: #fff;
  color: #666;
}

.button.white {
  margin-top: 10px;
  margin-bottom: auto;
  border-color: #fff;
  color: #fff;
}

.button.chat {
  display: block;
  margin-bottom: 15px;
}

.button.chat.last {
  margin-bottom: 0px;
}

.footerelementwrapper {
  padding-right: 20px;
}

.bottomfooter {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f5f5f5;
  color: #dedfde;
  font-size: 12px;
  font-weight: 300;
  text-align: right;
}

.new {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #666;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  font-family: acta-display, sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 52px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-shadow: none;
}

.slidenav {
  display: none;
}

.formwrapper {
  position: relative;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 0px;
}

.mega-col {
  padding-right: 0px;
  padding-left: 0px;
}

.social-icon {
  display: inline-block;
  border-radius: 5px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
}

.social-icon.hide {
  display: none;
}

.tint {
  background-color: #f0f0f0;
}

.price-old {
  color: #aaa;
  text-decoration: line-through;
}

.hidemobile {
  font-weight: 400;
}

.google-translate {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  display: block;
  height: 50px;
  max-width: 200px;
  margin-top: 8px;
  margin-right: auto;
  margin-left: auto;
}

.legal-info {
  float: left;
  clear: both;
}

.menu-button {
  float: left;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
}

.m-filter-wrapper {
  margin-bottom: 20px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border: 1px solid #ddd;
  background-color: #eee;
  color: #666;
  font-weight: 400;
}

.dropdown-icon {
  margin-right: 14px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: #222;
}

.dropdown-icon.hide {
  display: block;
  margin-right: 15px;
}

.social-section-footer {
  display: none;
  padding-top: 20px;
  padding-bottom: 13px;
  background-color: #222;
  color: hsla(0, 0%, 100%, 0.6);
  text-align: center;
}

.social-section-footer.top {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

.social-link {
  display: inline-block;
  width: 44px;
  height: 44px;
  margin-top: 7px;
  margin-right: 7px;
  padding-top: 11px;
  border: 1px solid transparent;
  border-radius: 100%;
  background-color: #7fc6d0;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  font-family: 'Social media', sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.14);
}

.social-link:hover {
  border-color: #222;
  background-color: transparent;
  color: #222;
  text-decoration: none;
  text-shadow: none;
}

.social-link.top {
  width: 38px;
  height: 44px;
  margin-right: 0px;
  margin-bottom: 0px;
  border-color: transparent;
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 15px;
}

.social-link.top:hover {
  background-color: transparent;
  color: #cbb682;
}

.social-link.facebook {
  border-style: solid;
  border-color: #fff;
  border-radius: 0%;
  background-color: transparent;
  color: hsla(0, 0%, 100%, 0.49);
  font-size: 220px;
}

.social-link.white {
  float: none;
  background-color: #fff;
  color: #7fc6d0;
}

.social-link.white:hover {
  border-color: #7fc6d0;
}

.social-link._2 {
  margin-top: 0px;
}

.image {
  color: #e7236f;
}

.nav-right {
  display: inline-block;
  padding-right: 10px;
  float: right;
}

.banner-container {
  position: relative;
  left: 0px;
  top: 50%;
  right: 0px;
  z-index: 10;
  padding-right: 12%;
  padding-left: 12%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #fff;
  font-size: 19px;
  line-height: 27px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.66);
}

.banner-container._2 {
  padding-right: 5%;
  padding-left: 5%;
}

.nav-container {
  position: relative;
}

.searchform-wrapper {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  margin: 30px auto 0px;
  float: left;
  background-color: transparent;
  opacity: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.searchform {
  position: relative;
}

.searchform-close {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 48px;
  height: 48px;
  padding-top: 13px;
  background-color: #555;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: 'Corin design', sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.searchform-close:hover {
  background-color: #cbb682;
}

.top-right {
  float: right;
}

.topnav-left {
  float: left;
}

.topnav {
  display: none;
  border-top: 3px none #999;
  background-color: #eee;
}

.flex-col {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-width: 33.33%;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #f7f7f7;
}

.title-wrapper {
  position: relative;
  z-index: 10;
  margin-bottom: 40px;
  padding-right: 12%;
  padding-left: 12%;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
}

.title-wrapper.small {
  margin-bottom: 25px;
  font-size: 17px;
  line-height: 25px;
}

.title-wrapper.testimonials {
  margin-bottom: 30px;
}

.title-wrapper.no-space {
  margin-bottom: 0px;
}

.title-wrapper._2 {
  margin-bottom: 23px;
  padding-right: 0%;
  padding-left: 0%;
}

.title-wrapper.space {
  margin-top: 30px;
  padding-top: 0px;
}

.slider-button {
  display: inline-block;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 27px 10px;
  border: 1px solid transparent;
  background-color: #d07fa2;
  box-shadow: none;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  color: #fff;
  font-size: 13px;
  line-height: 21px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-shadow: none;
}

.slider-button:hover {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
}

.slider-button._2 {
  margin-right: auto;
  margin-left: auto;
}

.slider-button.call-to-action {
  margin-top: 10px;
  margin-bottom: 20px;
}

.slider-button.call-to-action:hover {
  border-color: #d07fa2;
  color: #d07fa2;
}

.slider-button.call-to-action._2 {
  margin-top: 0px;
  margin-bottom: auto;
}

.slider-button.call-to-action.usp-call-to-action {
  margin-top: auto;
  margin-bottom: auto;
}

.slider-button.pink-hover:hover {
  border-color: #d07fa2;
  color: #d07fa2;
}

.flex-row-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px none #000;
}

.flex-col-copy {
  padding: 9vw 6% 10vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 100%, 0)), to(#eee));
  background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #eee);
  text-align: left;
}

.flex-col-copy.homeimage1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 340px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0)), to(#eee)), url('../images/1_1.jpg');
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #eee), url('../images/1_1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  color: #fff;
}

.category-title-wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  padding: 15px 10px 5px;
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  line-height: 23px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.8);
}

.main-wrapper {
  overflow: hidden;
  background-color: #fff;
}

.banner-text-medium {
  margin-bottom: 10px;
  padding-top: 0px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
}

.banner-text-medium._2 {
  color: #a7acb0;
  font-size: 14px;
  font-weight: 500;
  text-shadow: none;
}

.banner-text-medium._3 {
  color: #fff;
  font-weight: 500;
  text-align: center;
  text-shadow: none;
}

.banner-text-medium._3.usp-title {
  margin-bottom: auto;
  font-size: 22px;
  line-height: 29px;
  font-weight: 400;
  text-transform: none;
}

.banner-text-medium._1 {
  text-shadow: none;
}

.banner-text-medium._4 {
  margin-bottom: 20px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
}

.top-footer {
  display: none;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: #444;
  background-image: linear-gradient(315deg, rgba(0, 0, 0, 0.1), transparent);
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 14px;
  font-weight: 300;
}

.category-image {
  min-width: 100%;
  -webkit-filter: sepia(0%);
  filter: sepia(0%);
  -webkit-transition: opacity 500ms ease, -webkit-filter 800ms ease, -webkit-transform 900ms ease;
  transition: opacity 500ms ease, -webkit-filter 800ms ease, -webkit-transform 900ms ease;
  transition: filter 800ms ease, transform 900ms ease, opacity 500ms ease;
  transition: filter 800ms ease, transform 900ms ease, opacity 500ms ease, -webkit-filter 800ms ease, -webkit-transform 900ms ease;
}

.category-image:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.slider-nav {
  bottom: 20px;
  display: none;
  font-size: 12px;
}

.minislider-section {
  display: none;
  background-color: #fff;
}

.newsletter-section {
  display: none;
  padding-top: 28px;
  padding-bottom: 30px;
  background-color: #888;
  background-image: linear-gradient(315deg, rgba(0, 0, 0, 0.1), transparent);
  color: #fff;
}

.slider-container {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  bottom: auto;
  z-index: 10;
  padding-bottom: 0px;
  background-image: none;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #fff;
  text-align: left;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
}

.testimomonial-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.input-wrapper {
  position: relative;
}

.newsletter-column {
  padding-top: 10px;
}

.success-message {
  padding-top: 30px;
  padding-bottom: 10px;
  background-color: #666;
  color: #cbb682;
}

.error-message {
  padding-top: 20px;
  padding-bottom: 1px;
  color: #666;
  text-align: center;
}

.newsletter-heading {
  margin-bottom: 0px;
}

.nav-ad {
  display: none;
  min-height: 110px;
  background-color: #000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, contain;
  background-repeat: repeat, repeat;
}

.slider-image1 {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-image: none;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.slider-image2 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.slider-image2.slider-img2 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null));
  background-image: linear-gradient(180deg, null, null);
  background-size: auto, 2567px;
}

.slider-image2.slide-img3 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: linear-gradient(180deg, transparent, transparent);
  background-size: auto, cover;
}

.slider-image2.slider-img4 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: linear-gradient(180deg, transparent, transparent);
  background-size: auto, cover;
}

.slider-image2._2 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: linear-gradient(180deg, transparent, transparent);
  background-size: auto, cover;
}

.blog-sidebar {
  padding-left: 10px;
}

.banner {
    position: relative;
    overflow: hidden;
    height: 400px;
    background-color: #ddd;
    background-image: none;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
}

.about-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.contact-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  -webkit-filter: none;
  filter: none;
}

.contact-bg.collection-img {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-size: auto, cover;
}

.contact-bg.about-us {
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: linear-gradient(180deg, transparent, transparent);
  background-size: auto, cover;
}

.home {
  padding-top: 0px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

._404-img {
  display: none;
  max-width: auto;
  margin-bottom: 20px;
}

.mega-list.w--open {
  position: absolute;
  left: 0px;
  right: auto;
  width: 940px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-left: 10px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
}

.mega-list.link-dropdown.w--open {
  width: auto;
  padding-bottom: 10px;
}

.back-top-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 9000;
  display: block;
  width: 44px;
  height: 44px;
  padding-top: 11px;
  border-radius: 100%;
  background-color: #666;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  text-align: center;
}

.back-top-top:hover {
  background-color: #7fc6d0;
  color: #fff;
}

.back-top-top.w--current {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9000;
  display: inline-block;
  width: 44px;
  height: 44px;
  padding: 10px 0px 0px;
  border-radius: 100%;
  background-color: #666;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: hsla(0, 0%, 100%, 0.6);
}

.back-top-top.w--current:hover {
  background-color: #777;
}

.banner-blog {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: 0px 0px, 50% 24%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.team-content-wrapper {
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  text-align: center;
}

.blog-detail-img {
  margin-bottom: 20px;
}

.blog-img-large {
  display: block;
  min-height: 612px;
  padding-bottom: 0%;
  -webkit-transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
}

.blog-img-large._1 {
  background-image: none;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-large._1._2 {
  background-image:none;
  background-size: cover;
}

.blogpost-caption {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  text-align: left;
}

.timestamp-img {
  margin-top: -1px;
  margin-right: 6px;
  margin-left: 6px;
}

.blog-img-medium {
  display: block;
  width: 100%;
  padding-bottom: 66%;
  -webkit-transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
}

.blog-img-medium._1 {
  background-image: none;
  background-position: 50% 50%;
  background-size: cover;
}

.blog-img-medium._1._3 {
  background-image: none;
  background-size: cover;
}

.blog-img-medium._2 {
  background-image: none;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-medium._2._4 {
  background-image: none;
  background-size: cover;
}

.blog-img-medium._5 {
  background-image: none;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-medium._6 {
  background-image:none;
  background-position: 50% 50%;
  background-size: cover;
}

.blog-img-medium._7 {
  background-image: none;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-layout-boxed-featured .blogpost-category {
  position: relative;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 15px;
  padding: 7px 14px;
  border: 1px solid #666;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  color: #666;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blog-layout-boxed-featured .blogpost-category:hover {
  background-color: #565656;
  color: #fff;
  text-shadow: none;
}

.blogposts-block {
  margin-right: -20px;
  margin-left: -20px;
}

.blogpost-row {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.blogpost-text {
  padding-left: 20px;
}

.blog-img-small {
  display: block;
  padding-bottom: 100%;
  background-image: none;
  background-position: 0px 0px;
  background-size: 125px;
}

.blog-img-small._1 {
  background-image: none;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

    .blog-img-small._2 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .blog-img-small._5 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .blog-img-small._6 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
    }

    .blog-img-small._3 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .blog-img-small._4 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

.category-link {
  display: block;
  color: hsla(0, 0%, 40%, 0.6);
}

.category-link:hover {
  color: #666;
}

.category-link.w--current {
  color: #666;
}

.blog-col {
  padding-right: 0px;
  padding-left: 0px;
}

.blogpost-hover {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  overflow: hidden;
  height: 0px;
  padding: 20px;
  background-color: rgba(17, 17, 17, 0.8);
  opacity: 0;
  color: #fff;
  text-align: center;
}

.center-blog {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.product-code {
  margin-bottom: 7px;
  color: #999;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}

.social-section-header {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  color: hsla(0, 0%, 100%, 0.6);
  text-align: center;
}

.social-group-wrapper {
  display: block;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  text-align: center;
}

.social-feed-wrapper {
  display: inline-block;
  max-width: 400px;
  margin-right: 10px;
  margin-left: 10px;
}

.social-feed-embed {
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 6px;
}

.social-col {
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.social-col:hover {
  opacity: 0.8;
}

.social-row {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
}

.testimonial-slider {
  height: auto;
  margin-bottom: 50px;
  color: #666;
}

.testimonial-slide {
  padding-right: 9%;
  padding-left: 9%;
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 23px;
  line-height: 31px;
  font-weight: 300;
  text-align: center;
}

.testimonial-slider-nav {
  left: 0px;
  right: 0px;
  bottom: -50px;
  font-size: 12px;
}

.cta-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.testimonials-element-wrapper {
  text-align: left;
}

.social-icon-wrapper {
  float: none;
}

.footer-img {
  display: none;
  min-width: 50%;
  float: none;
  text-align: left;
}

.footer-usps {
  display: none;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #555;
  background-image: linear-gradient(315deg, rgba(0, 0, 0, 0.1), transparent);
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 14px;
  font-weight: 300;
}

.usp1 {
  display: inline-block;
  width: 33.33%;
  padding-right: 20px;
  padding-left: 20px;
  float: left;
  text-align: center;
}

.banner-cta {
  display: none;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #666;
  color: hsla(0, 0%, 100%, 0.6);
}

.banner-usp {
  padding-right: 20px;
  padding-left: 48px;
  text-align: left;
}

.banner-cta-icon {
  position: absolute;
  left: 10px;
  top: 20px;
  float: left;
}

.usp-img {
  opacity: 0.6;
}

.footer-payment-img {
  display: none;
}

.midnav {
  border-top: 3px none #999;
  background-color: #fff;
}

.custom-stock-message {
  margin-top: 6px;
  margin-bottom: 6px;
  color: #41bd3c;
}

.flex-parent {
  display: block;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1%;
  padding-left: 1%;
  text-align: center;
}

.flex-child {
  position: relative;
  display: inline-block;
  width: 64%;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  float: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.flex-child.small {
  width: 31%;
}

.flex-child._1 {
  margin-bottom: 25px;
}

.img-overlay {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 600px;
    margin-right: auto;
    margin-left: auto;
    border: 1px none #eee;
    background-image: none;
    background-position: 0px 0px;
    background-size: 125px;
}

.img-overlay._1 {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(33%, transparent), to(hsla(0, 0%, 100%, 0.15))), url('../images/banner1.jpg');
  background-image: linear-gradient(180deg, transparent 33%, hsla(0, 0%, 100%, 0.15)), url('../images/banner1.jpg');
  background-position: 0px 0px, 20% 50%, 0px 0px;
  background-size: auto, cover, 125px;
  background-repeat: repeat, no-repeat, repeat;
}

.img-overlay._2 {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(33%, transparent), to(hsla(0, 0%, 100%, 0.15)));
  background-image: linear-gradient(180deg, transparent 33%, hsla(0, 0%, 100%, 0.15));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

    .img-overlay._3 {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
    }

.img-overlay._4 {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(33%, transparent), to(hsla(0, 0%, 100%, 0.15)));
  background-image: linear-gradient(180deg, transparent 33%, hsla(0, 0%, 100%, 0.15));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.img-overlay._5 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.15)), color-stop(66%, transparent));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), transparent 66%);
  background-position: 0px 0px, 50% 50%, 0px 0px;
  background-size: auto, cover, 125px;
}

.img-overlay._6 {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(33%, transparent), to(hsla(0, 0%, 100%, 0.15)));
  background-image: linear-gradient(180deg, transparent 33%, hsla(0, 0%, 100%, 0.15));
  background-position: 0px 0px, 50% 50%, 0px 0px;
  background-size: auto, cover, 125px;
  background-repeat: repeat, no-repeat, repeat;
}

.center-flex {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 20px 10px 30px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  color: #fff;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.center-flex._4 {
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
}

.center-flex._6 {
  text-align: center;
}

.center-flex._2 {
  text-align: center;
}

.products {
  padding-right: 80px;
  padding-left: 80px;
}

.row-portfolio {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.elementwrapper {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
}

.productname {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 19px;
}

.productprice {
  margin-bottom: 10px;
}

.newlabel {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 52px;
  height: 52px;
  padding-top: 16px;
  border-radius: 100%;
  background-color: #7fc6d0;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  color: #fff;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.productimg {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(86, 86, 86, 0.11);
}

.brandsection {
  position: relative;
  padding: 130px 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  color: #fff;
  font-size: 19px;
  line-height: 27px;
  font-weight: 300;
  text-align: center;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
}

.brandsection.call-to-action-section {
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(127, 198, 208, 0.9)), to(rgba(127, 198, 208, 0.9)));
  background-image: linear-gradient(180deg, rgba(127, 198, 208, 0.9), rgba(127, 198, 208, 0.9));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.brandsection.call-to-action-section._2 {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #d8d8d8;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
}

.brandsection.call-to-action-section._2.hide {
  display: none;
}

.brandsection.call-to-action-section.hide {
  display: block;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.31)), to(rgba(0, 0, 0, 0.31)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.31), rgba(0, 0, 0, 0.31));
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.brandsection.hide {
  display: none;
}

.home-element-wrapper {
  display: inline-block;
  width: 33.33%;
  margin-bottom: 60px;
  padding-right: 10px;
  padding-left: 10px;
  float: left;
  font-size: 17px;
  font-weight: 300;
}

.home-icon {
  margin-bottom: 10px;
  padding: 10px;
}

.text-align-center {
  text-align: center;
}

.product-block {
  padding-right: 0px;
  padding-left: 0px;
}

.hide-mobile {
  font-size: 12px;
}

.footer-column {
  text-align: right;
}

.div-block-3 {
  background-color: #fff;
  color: #aaa;
}

.div-block-3.grey {
  background-color: #f5f5f5;
}

.colour1 {
  color: #646464;
  font-weight: 500;
}

.colour1._2 {
  color: #565656;
}

.container-wide {
  position: relative;
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
  color: #222;
  text-align: left;
}

.container-wide.text-center {
  text-align: center;
}

.container-wide._2 {
  width: auto;
  margin-right: 5%;
  margin-left: 5%;
}

.container-wide._2._1 {
  margin-right: 10%;
  margin-left: 10%;
}

.container-wide._3 {
  width: auto;
  padding-right: 55px;
  padding-left: 55px;
}

.container-wide.center {
  text-align: center;
}

.container-wide.nav {
  position: relative;
  text-align: center;
}

.text-block {
  color: #222;
  font-size: 13px;
  font-weight: 500;
}

.text-block:hover {
  color: #7fc6d0;
}

.body {
  padding-top: 78px;
  font-family: Quicksand, sans-serif;
}

.container {
  text-align: center;
}

.container._2 {
  padding-right: 20px;
}

.div-block-4 {
  text-align: center;
}

.div-block-5 {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f6f6f6;
  color: #fff;
  text-transform: none;
}

.brand-image-wrapper {
    height: 300px;
}

    .brand-image-wrapper._1 {
        height: 350px;
        border-style: solid;
        border-width: 1px;
        border-color: #e9e9e9;
        background-image: none;
        background-position: 0px 0px;
        background-size: cover;
        background-repeat: no-repeat;
    }

        .brand-image-wrapper._1.cat-img1 {
            height: 450px;
            margin-right: auto;
            margin-left: auto;
            background-image: none;
            background-size: cover;
        }

            .brand-image-wrapper._1.cat-img1.colour1 {
                background-image: none;
                background-size: cover;
            }

            .brand-image-wrapper._1.cat-img1.cat-img3 {
                background-image: none;
                background-size: cover;
            }

        .brand-image-wrapper._1.cat-img2._3 {
            background-image: none;
            background-size: cover;
        }

        .brand-image-wrapper._1.cat-img6 {
            background-image: none;
            background-size: cover;
        }

        .brand-image-wrapper._1.cat-img4 {
            background-image: none;
            background-size: cover;
        }

    .brand-image-wrapper._2 {
        height: 350px;
        background-image: none;
        background-position: 0px 0px;
        background-size: cover;
    }

        .brand-image-wrapper._2.img1 {
            border: 1px none #e9e9e9;
            background-image: none;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .brand-image-wrapper._2.img3 {
            border: 1px none #e9e9e9;
            background-image: none;
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
        }

    .brand-image-wrapper._3 {
        height: 350px;
        border: 1px solid #e9e9e9;
        background-image: none;
        background-position: 0px 0px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .brand-image-wrapper._4 {
        height: 350px;
        background-image: none;
        background-position: 0px 0px;
        background-size: cover;
    }

        .brand-image-wrapper._4.facebook-box {
            background-color: #fff;
            background-image: none;
            background-position: 50% 50%;
            background-size: 256px;
            background-repeat: no-repeat;
        }

.devider {
  width: 50px;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  background-color: #222;
}

.devider.white {
  background-color: #fff;
}

.heading {
  font-family: Quicksand, sans-serif;
  color: #646464;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
}

.heading._2 {
  font-size: 14px;
}

.heading.left {
  text-align: left;
}

.heading.white {
  color: #fff;
}

.column {
  text-align: center;
}

.div-block-6 {
  padding-left: 0px;
  text-align: center;
}

.div-block-6.white {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
}

.div-block-6.white.left {
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-6.white.right {
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-left: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.cat-wrapper {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.cat-wrapper.big-box {
  margin-bottom: 40px;
}

.div-block-7 {
  height: 400px;
}

    .div-block-7._1 {
        background-image: none;
        background-position: 0px 0px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .div-block-7._2 {
        background-image: none;
        background-position: 0px 0px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .div-block-7._3 {
        background-image: none;
        background-position: 0px 0px;
        background-size: cover;
        background-repeat: no-repeat;
    }

.column-2 {
  padding-right: 0px;
  padding-left: 0px;
}

.column-3 {
  padding-right: 0px;
  padding-left: 0px;
}

.column-4 {
  padding-right: 0px;
  padding-left: 0px;
}

.read-more-text {
  padding-bottom: 21px;
  font-family: Rhesmanisa, sans-serif;
  color: #fff;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 4px;
  text-decoration: none;
  text-transform: capitalize;
}

.read-more-text.center {
  padding-top: 40px;
  padding-bottom: 40px;
  color: #222;
  font-size: 55px;
  font-weight: 700;
  letter-spacing: 6px;
}

.read-more-text._2 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}

.read-more-text._2._1 {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  letter-spacing: 1px;
  text-transform: none;
}

.div-block-8 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(34, 34, 34, 0.31);
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  color: rgba(54, 34, 34, 0.49);
  text-align: center;
}

.div-block-8.center {
  left: 10%;
  right: 10%;
  background-color: #fff;
}

.div-block-9 {
  margin-right: 10px;
  margin-left: 10px;
}

.column-5 {
  position: relative;
  text-align: center;
}

.icon-wrapper {
    display: block;
    width: 50px;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
    background-image: none;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

    .icon-wrapper._2 {
        background-image: none;
        background-size: cover;
    }

    .icon-wrapper._3 {
        background-image: none;
        background-size: cover;
    }

    .icon-wrapper._4 {
        background-image: none;
        background-size: cover;
    }

.heading-2 {
  margin-top: 0px;
  color: #646464;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}

.paragraph-3 {
  margin-bottom: 0px;
  color: #646464;
  font-size: 16px;
}

.paragraph-3._2 {
  margin-bottom: 10px;
}

.text-span {
  color: #7fc6d0;
  font-size: 17px;
  font-weight: 500;
}

.newsletter-section-2 {
  padding-bottom: 10px;
  background-color: #7fc6d0;
  background-image: none;
  color: #fff;
}

.input2-2 {
  position: relative;
  height: 44px;
  padding: 0px 14px;
  border: 1px none #666;
  border-radius: 0px;
  background-color: #fff;
}

.login-btn-2 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 48px;
  height: 48px;
  padding: 0px;
  float: right;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
  font-family: 'Corin design', sans-serif;
  color: #aaa;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}

.login-btn-2:hover {
  border: 1px none #666;
  background-color: #555;
  opacity: 1;
  color: #fff;
}

.success-message-2 {
  padding-top: 30px;
  padding-bottom: 10px;
  background-color: #666;
  color: #cbb682;
}

.error-message-2 {
  padding-top: 20px;
  padding-bottom: 1px;
  color: #666;
  text-align: center;
}

.heading-3 {
  font-weight: 400;
}

.heading-4 {
  font-weight: 400;
}

.footer-heading-wrapper {
  color: #646464;
}

.nav-link-2 {
  margin-top: 0px;
  padding: 26px 20px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.nav-link-2:hover {
  color: #fff;
  text-decoration: none;
}

.nav-link-2.w--current {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
}

.nav-link-2.mid {
  padding: 30px 10px;
  font-family: 'Open Sans', sans-serif;
  color: hsla(0, 0%, 100%, 0.9);
  font-size: 15px;
  font-weight: 400;
}

.nav-link-2.mid:hover {
  color: #fff;
}

.nav-link-2.mid.w--current {
  background-color: transparent;
  color: #fff;
}

.text-block-2 {
  color: #646464;
}

.heading-5 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 1px;
  text-transform: none;
}

.heading-5.white {
  padding-top: 0px;
  color: #fff;
}

.uppercase {
  font-weight: 700;
  text-transform: uppercase;
}

.top-bar {
  background-color: #7fc6d0;
  color: #fff;
  text-align: center;
}

.top-bar-text {
  padding-top: 7px;
  padding-bottom: 5px;
  font-family: Quicksand, sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1.5px;
}

.text-slider {
  height: auto;
  background-color: transparent;
}

.slider-img {
  display: block;
  width: 160px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 100%;
  opacity: 1;
}

.left-arrow {
  left: 15px;
  width: 50px;
}

.slider-title {
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: Quicksand, sans-serif;
  color: #222;
  font-size: 44px;
  font-weight: 300;
  text-transform: uppercase;
}

.slider-title.description {
  font-family: Quicksand, sans-serif;
  color: #565656;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  text-transform: none;
}

.text-slider-right {
  position: absolute;
  top: 50%;
  right: 0%;
  bottom: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.slider-arrow {
  width: 50px;
  height: 50px;
  margin-right: 0px;
  padding-top: 14px;
  border-radius: 100%;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  color: #565656;
  font-size: 22px;
  font-weight: 700;
}

.slider-arrow:hover {
  background-color: transparent;
}

.slider-arrow.left {
  margin-left: 0px;
  background-color: transparent;
  color: #565656;
}

.right-arrow {
  right: 15px;
  width: 50px;
}

.text-slider-left {
  padding-right: 10px;
  text-align: left;
}

.text-slider-row {
  position: relative;
  width: 20%;
  text-align: left;
}

.img-wrapper {
  position: relative;
  height: 400px;
  border: 1px solid #e9e9e9;
  background-image: url('../images/L1010659-PSEdit-min.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  /*-webkit-transition: all 500ms ease;
  transition: all 500ms ease;*/
}

.img-wrapper:hover {
  background-image: url('../images/C275.jpg');
  background-size: cover;
}

.img-wrapper._2 {
  background-image: url('../images/__700x9999.B956EC8C-5056-B779-24AB2B2DF0A4AC8E-featured_image.jpg');
  background-size: 350px;
}

.img-wrapper._3 {
  height: 300px;
  background-image: url('../images/PIC_9_2.jpg');
  background-size: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.img-wrapper._3:hover {
  background-image: url('../images/gift.jpg');
  background-size: cover;
}

.img-wrapper._3._1 {
  background-image: url('../images/gift.jpg');
  background-size: cover;
}

.img-wrapper._3._2 {
  border: 1px solid #e9e9e9;
  background-image: url('../images/L1010231-PSEdit-min.jpg');
  background-position: 50% 50%;
  background-size: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.img-wrapper._3._2:hover {
  background-image: url('../images/Arty_Shot_21.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.img-wrapper._3._2.brand-img3 {
  background-image: url('../images/36381360_1784094001674292_932819746540224512_o.jpg');
  background-size: cover;
}

.img-wrapper._3._2.brand-img3:hover {
  background-image: url('../images/mack-logo2.png');
  background-size: contain;
}

.img-wrapper._3._4 {
  background-image: url('../images/L1000176-PSEdit-min.jpg');
  background-size: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.img-wrapper._3._4:hover {
  background-image: url('../images/P1332PS.jpg');
  background-size: cover;
}

.img-wrapper._3._4.brand-img {
  background-image: url('../images/L1070134-min.jpg');
  background-size: cover;
}

.img-wrapper._3._4.brand-img:hover {
  background-image: url('../images/celtic-lands-logo-1.png');
  background-size: cover;
}
.img-wrapper._3.brand-img5 {
    background-image: url('../images/L1010659-PSEdit-min.jpg');
    background-size: cover;
}

.img-wrapper._3.brand-img5:hover {
  background-image: url('../images/mack-logo2.png');
  background-size: contain;
}

.img-wrapper._4 {
  background-image: url('../images/__700x9999.B55087B8-5056-B762-9038A95B5D0A0827-featured_image.jpg');
  background-size: cover;
}

.img-wrapper.top {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: cover;
}

.img-wrapper._1 {
  background-image: url('../images/L1070226_600_400_80_c1.jpg');
  background-size: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.img-wrapper._1:hover {
  background-image: url('../images/mackintosh-homepage.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.img-wrapper._1.brand-img2 {
  background-image: url('../images/L1010342-PSEdit-min.jpg');
  background-size: cover;
}

.img-wrapper._1.brand-img2:hover {
  background-image: url('../images/silver-origins.png');
  background-size: contain;
}

.img-wrapper._5 {
  border: 1px solid #e9e9e9;
  background-image: url('../images/__700x9999.B55087B8-5056-B762-9038A95B5D0A0827-featured_image.jpg');
  background-position: 50% 50%;
  background-size: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.img-wrapper._5:hover {
  background-image: url('../images/9114_1.jpg');
  background-size: cover;
}

.img-wrapper._5.brand-img4 {
  background-image: url('../images/necklaces.jpg');
  background-size: cover;
}

.img-wrapper._5.brand-img4:hover {
  background-image: url('../images/celtic-lands-logo-2.png');
  background-size: cover;
}

.img-wrapper.brand-img6 {
  background-image: url('../images/__700x9999.B17B238D-5056-B762-90964C60C30213F8-featured_image.jpg');
  background-size: cover;
}

.img-wrapper.brand-img6:hover {
  background-image: url('../images/SEA-GEMS-FINE-ENAMELS-LOGO-1.gif');
  background-size: cover;
}

.description-wrapper {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #646464;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
}

.heading-6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Quicksand, sans-serif;
  color: #646464;
  font-weight: 500;
}

.column-6 {
  padding-right: 20px;
  padding-left: 20px;
}

.column-7 {
  padding-right: 20px;
  padding-left: 20px;
}

.column-8 {
  padding-right: 20px;
  padding-left: 20px;
}

.read-more {
  font-size: 13px;
  font-weight: 500;
}

.video {
  height: 600px;
}

.html-embed {
  position: relative;
  height: 600px;
  background-color: #7fc6d0;
}

.html-embed.hide {
  display: none;
}

.grid {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: ".";
  -ms-grid-columns: 3fr 16px 4fr;
  grid-template-columns: 3fr 4fr;
  -ms-grid-rows: auto 16px 2fr 16px minmax(38px, 1fr) 16px auto;
  grid-template-rows: auto 2fr minmax(38px, 1fr) auto;
}

.div-block-10 {
  height: 400px;
}

.column-9 {
  text-align: center;
}

.columns {
  margin-bottom: auto;
}

.call-to-action-section {
  background-color: #f5f5f5;
  text-align: center;
}

.testimonial-image-wrapper {
    width: 200px;
    height: 200px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 505%;
    background-image: none;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

    .testimonial-image-wrapper._2 {
        background-image: none;
        background-size: cover;
    }

    .testimonial-image-wrapper._3 {
        border-radius: 50%;
        background-color: #fff;
        background-image: none;
        background-size: cover;
    }

    .testimonial-image-wrapper._4 {
        background-color: #fff;
        background-image: none;
        background-size: 183px;
    }

    .testimonial-image-wrapper._5 {
        background-color: #fff;
        background-image: none;
        background-size: 183px;
    }

.testimonial-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.paragraph-4 {
  font-style: normal;
}

.brand-box {
  height: auto;
  padding: 10px 60px;
  border-right: 1px none #e0e0e0;
}

.brand-description {
  margin: 20px;
  text-align: center;
}

.brand-description._2 {
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  color: #fff;
  font-size: 16px;
  text-align: center;
}

.brand-description._1 {
  margin-top: 0px;
}

.column-10 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(127, 198, 208, 0.9)), to(rgba(127, 198, 208, 0.9)));
  background-image: linear-gradient(180deg, rgba(127, 198, 208, 0.9), rgba(127, 198, 208, 0.9));
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.column-10._1 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(214, 197, 113, 0.9)), to(rgba(214, 197, 113, 0.9)));
  background-image: linear-gradient(180deg, rgba(214, 197, 113, 0.9), rgba(214, 197, 113, 0.9));
  background-size: auto, cover;
}

.paragraph-5 {
  color: #646464;
  line-height: 24px;
  font-weight: 300;
}

.paragraph-5._2 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #565656;
  text-shadow: none;
}

.paragraph-5._2._1 {
  display: inline-block;
  margin-top: 10px;
  padding-right: 20px;
  float: none;
  color: #646464;
  font-weight: 500;
  text-align: left;
}

.text-span-2 {
  color: #7fc6d0;
  font-weight: 500;
}

.div-block-11 {
  text-align: center;
}

.div-block-12 {
  position: relative;
  background-color: #7fc6d0;
}

.div-block-13 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99999;
}

.div-block-14 {
  position: relative;
  height: 500px;
  margin-right: auto;
  margin-left: auto;
  background-color: #7fc6d0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(127, 198, 208, 0.85)), to(rgba(127, 198, 208, 0.85)));
  background-image: linear-gradient(180deg, rgba(127, 198, 208, 0.85), rgba(127, 198, 208, 0.85));
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
}

.div-block-14.hide {
  display: none;
}

.image-2 {
  position: relative;
  top: 20px;
  display: block;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
}

.paragraph-6 {
  color: #7fc6d0;
  font-size: 16px;
  font-weight: 400;
}

.subtitle {
  margin-top: 10px;
  font-size: 16px;
}

.paragraph-7 {
  color: #565656;
}

.usp-box {
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 30px 60px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-align: center;
}

.usp-box:hover {
  box-shadow: 6px 6px 10px 0 rgba(34, 68, 31, 0.12);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.usp-box.blue {
  margin-top: 0px;
  margin-bottom: 30px;
  background-color: #7fc6d0;
}

.usp-box.gold {
  margin-top: auto;
  background-color: #d6c571;
}

.link-block {
  display: block;
}

.usp-text {
  margin-top: 20px;
}

.div-block-15 {
  color: #fff;
  font-size: 17px;
}

.div-block-16 {
  margin-bottom: 10px;
}

.div-block-17 {
  margin-bottom: 10px;
}

.div-block-18 {
  margin-bottom: 10px;
}

.body-2 {
  font-family: Quicksand, sans-serif;
}

.heading-7 {
  font-family: Quicksand, sans-serif;
}

.div-block-19 {
  font-family: Quicksand, sans-serif;
}

.login-section {
  font-family: Quicksand, sans-serif;
}

.div-block-20 {
  font-family: Quicksand, sans-serif;
}

.create-account {
  font-family: Quicksand, sans-serif;
}

.vide-text-wrapper {
  position: absolute;
  left: 0%;
  top: 50%;
  right: 0%;
  bottom: 0%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #fff;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
}

.banner-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-shadow: none;
}

.paragraph-8 {
  font-weight: 400;
}

.body-3 {
  font-family: Quicksand, sans-serif;
}

.text-block-3 {
  display: inline-block;
}

.text-block-4 {
  display: inline-block;
}

.text-block-5 {
  display: inline-block;
}

.text-block-6 {
  display: inline-block;
}

.text-block-7 {
  display: inline-block;
}

.text-block-8 {
  display: inline-block;
}

.link {
  display: block;
}

.div-block-21 {
    height: 400px;
    margin-bottom: 20px;
    background-image: none;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

    .div-block-21._2 {
        background-image: none;
        background-size: cover;
    }

.about-image {
    height: 350px;
    background-image: none;
    background-position: 0px 0px;
    background-size: 125px;
}

    .about-image._1 {
        background-image: none;
        background-size: cover;
    }

    .about-image._2 {
        background-image: none;
        background-size: cover;
    }

    .about-image._3 {
        background-image: none;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .about-image._4 {
        background-image: none;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .about-image.collection-image {
        background-image: none;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

.columns-2 {
  margin-bottom: 40px;
}

.about-text {
  padding-left: 7%;
}

.about-text.left {
  padding-right: 7%;
  padding-left: 0%;
}

.contact-title {
  margin-top: 20px;
}

.text-span-3 {
  color: #7fc6d0;
  font-weight: 500;
}

.topcart {
  overflow: hidden;
  height: 0px;
  clear: both;
  background-color: #fff;
}

.nav-link-3 {
  margin-top: 0px;
  padding: 22px 20px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: hsla(0, 0%, 40%, 0.6);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.nav-link-3:hover {
  color: #666;
  text-decoration: none;
}

.nav-link-3.w--current {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #666;
}

.nav-link-3.mid {
  display: inline-block;
  padding: 34px 10px 0px;
  float: right;
  clear: none;
  color: hsla(0, 0%, 40%, 0.6);
  text-transform: none;
}

.nav-link-3.mid:hover {
  color: #666;
}

.nav-link-3.mid.w--current {
  background-color: transparent;
  color: #666;
}

.fontello-2 {
  top: 0px;
  right: 0px;
  display: inline;
  font-family: 'Corin design', sans-serif;
  font-weight: 400;
}

.topcart-2 {
  position: relative;
  z-index: 9001;
  overflow: hidden;
  height: 0px;
  clear: both;
}

.columns-3 {
  background-color: #f5f5f5;
}

.mini-slider-img-2 {
  position: relative;
  width: 19%;
  height: auto;
  margin-right: 5px;
  margin-left: 5px;
  padding: 20px 30px;
  border: 1px none #ebebeb;
  background-color: transparent;
  box-shadow: none;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.image-19 {
  position: relative;
  left: 0%;
  top: 50%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.brand-wrapper {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 150px;
  margin-top: 10px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 20px 5%;
  background-color: #f0f2f9;
}

.mini-slider-2 {
  overflow: hidden;
  height: 230px;
  padding: 10px 5%;
  background-color: transparent;
  opacity: 0.9;
}

.rightarrow-2 {
  display: block;
}

.leftarrow-2 {
  display: block;
}

.brand-name {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  color: #4a4a49;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.columns-4 {
  margin-bottom: 20px;
}

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.paragraph-5-copy {
  color: #646464;
  line-height: 24px;
  font-weight: 300;
}

.paragraph-5-copy._2 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #565656;
  text-shadow: none;
}

.paragraph-5-copy._2._1 {
  margin-top: 10px;
  font-weight: 500;
}

.heading-8 {
  font-weight: 500;
}

.popup-wrapper {
  position: absolute;
  left: 0%;
  top: 21%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  overflow: visible;
  width: 600px;
  height: 500px;
  margin-right: auto;
  margin-left: auto;
  padding: 100px;
  opacity: 1;
}

.html-embed-2 {
  margin-right: auto;
  margin-left: auto;
}

.div-block-22 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.div-block-23 {
  top: 25%;
  display: none;
  width: 400px;
  height: auto;
  max-height: 480px;
  padding: 40px 60px 0px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  text-align: center;
}

.newsletter-text-wrapper {
  display: block;
  text-transform: none;
}

.member-wrapper {
  padding-right: 220px;
  padding-left: 220px;
}

.div-block-24 {
  display: block;
  border-radius: 50%;
}

.close-pupup {
  position: absolute;
  left: auto;
  top: -4%;
  right: -4%;
  bottom: auto;
  z-index: 9999;
  display: block;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #7fc6d0;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.chat-text {
  margin-bottom: 30px;
}

.image-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.heading-36 {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.heading-36:hover {
  color: #646464;
  text-decoration: underline;
}

.heading-36.w--current {
  color: #333;
}

.dropdownlink {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #646464;
  text-transform: none;
}

.dropdownlink:hover {
  background-color: #7fc6d0;
  color: #fff;
}

.dropdownlink.w--current {
  color: #d3c8b2;
  text-decoration: underline;
}

.dropdownlink.w--current:hover {
  color: #fff;
}

.accreditations-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
}

.div-block-25 {
  font-family: Quicksand, sans-serif;
}

.heading-37 {
  font-family: Quicksand, sans-serif;
}

.heading-38 {
  font-family: Quicksand, sans-serif;
}

.paragraph-9 {
  font-family: Quicksand, sans-serif;
}

.logo-img {
  height: 100px;
}

.logo-img.logo {
  width: auto;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.paragraph-10 {
  color: #999;
}

.paragraph-11 {
  font-family: Quicksand, sans-serif;
  color: #999;
}

.heading-39 {
  font-family: Quicksand, sans-serif;
}

.body-4 {
  font-family: 'Open Sans', sans-serif;
}

.heading-40 {
  font-family: Quicksand, sans-serif;
}

.heading-41 {
  font-family: Quicksand, sans-serif;
}

.heading-42 {
  font-family: Quicksand, sans-serif;
}

.logo-wrapper {
  width: 100px;
}

.heading-43 {
  font-family: Quicksand, sans-serif;
}

.column-11 {
  display: none;
}

.logo-link {
    width: 80px;
    height: 80px;
    margin-top: 10px;
    background-image: none;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

    .logo-link._2 {
        background-image: none;
        background-size: contain;
    }

.column-12 {
  text-align: left;
}

.columns-5 {
  margin-top: 46px;
  padding-top: 0px;
}

.link-block-2 {
  width: auto;
  padding-right: 30px;
  padding-left: 0px;
}

.link-block-3 {
  padding-right: 30px;
  padding-left: 0px;
}

.slider-2 {
  height: 500px;
}

.text-block-9 {
  margin-top: 61px;
  text-align: center;
}

.column-13 {
  padding-left: 10px;
}

.columns-6 {
  margin-left: auto;
}

.link-2 {
  color: #313030;
}

.link-3 {
  color: #313030;
}

.dropdown {
  position: static;
}

.dropdown:hover {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

.heading-44 {
  margin-bottom: 0px;
}

.dropdown-2 {
  position: static;
}

.image-24 {
  display: none;
}

.text-span-4 {
  font-weight: 400;
}

html.w-mod-js *[data-ix="megamenudisplaynone"] {
  display: none;
}

@media (max-width: 991px) {
  .nav-link.w--current {
    display: inline-block;
  }
  .nav-link._2 {
    padding-top: 26px;
    padding-bottom: 26px;
  }
  .nav-link.account-button.last._2 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .nav-menu {
    display: inline-block;
    float: left;
  }
  .nav-menu.top {
    padding-right: 10px;
    padding-left: 10px;
  }
  .section {
    padding-top: 40px;
    padding-bottom: 10px;
  }
  .section.products {
    padding-right: 10px;
    padding-left: 10px;
  }
  .section.grey {
    padding: 40px 20px;
  }
  .section._1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .mid-footer {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .copyright {
    float: none;
  }
  .header {
    font-size: 20px;
  }
  .brand {
    padding-left: 0px;
    float: left;
  }
  .brand.w--current {
    float: left;
  }
  .pull-left {
    float: left;
  }
  .pull-left.menu-btn {
    padding: 25px;
  }
  .arrow.miniarrow.right-arrow {
    display: block;
    width: auto;
    margin-right: -0.5px;
    margin-left: 45.5px;
  }
  .arrow.miniarrow.arrow-left {
    margin-left: -0.5px;
  }
  .mini-slider-img {
    width: 25%;
  }
  .formrow {
    margin-top: 20px;
  }
  .about-content-wrapper {
    top: 100%;
  }
  .dropdownlist.w--open {
    left: -195px;
    right: 0px;
  }
  .footerelementwrapper {
    margin-bottom: 20px;
  }
  .bottomfooter {
    padding-bottom: 20px;
    text-align: center;
  }
  .google-translate {
    margin-top: 0px;
  }
  .legal-info {
    margin-bottom: 8px;
    float: none;
    text-align: center;
  }
  .social-section-footer.top {
    display: none;
    clear: both;
  }
  .nav-right {
    display: inline-block;
  }
  .searchform-wrapper {
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
    float: none;
  }
  .flex-col {
    min-width: 50%;
  }
  .title-wrapper {
    margin-bottom: 20px;
  }
  .newsletter-section {
    padding-top: 40px;
  }
  .slider-container {
    padding-right: 12%;
    padding-left: 12%;
  }
  .newsletter-column {
    margin-bottom: 20px;
  }
  .mega-list.w--open {
    right: auto;
    display: block;
    max-width: 768px;
    padding-bottom: 20px;
  }
  .team-content-wrapper {
    top: 100%;
  }
  .blog-img-large {
    min-height: 0px;
    padding-bottom: 66%;
  }
  .blogpost-category-link {
    margin-bottom: 7px;
  }
  .blogposts-block {
    margin-right: -5px;
    margin-left: -5px;
  }
  .blogpost-row {
    padding-right: 5px;
    padding-left: 5px;
  }
  .blogpost-text {
    padding-left: 0px;
  }
  .blog-img-small {
    margin-bottom: 20px;
  }
  .category-link {
    margin-bottom: 7px;
  }
  .social-section-header {
    display: none;
  }
  .bottom-footer-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .banner-usp {
    padding-right: 0px;
  }
  .footer-payment-img {
    margin-bottom: 15px;
  }
  .flex-child {
    width: 58%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .flex-child.small {
    width: 38%;
  }
  .flex-child._1 {
    margin-right: auto;
    margin-left: auto;
    clear: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .center-flex {
    padding-right: 30px;
    padding-left: 30px;
  }
  .elementwrapper {
    margin-bottom: 20px;
  }
  .container-wide {
    max-width: 100%;
  }
  .container-wide._2 {
    margin-right: 0%;
    margin-left: 0%;
  }
  .container-wide.nav {
    width: auto;
  }
  .div-block-6 {
    margin-bottom: 20px;
  }
  .icon-wrapper {
    margin-top: 10px;
  }
  .nav-link-2.w--current {
    display: inline-block;
  }
  .nav-link-2.mid {
    font-size: 15px;
  }
  .slider-title {
    margin-bottom: 20px;
  }
  .text-slider-right {
    padding-right: 0px;
  }
  .text-slider-left {
    padding-top: 15px;
    padding-right: 10px;
    padding-left: 0px;
  }
  .text-slider-row {
    padding-right: 60px;
    padding-left: 60px;
  }
  .img-wrapper._3._4.brand-img:hover {
    background-size: contain;
  }
  .img-wrapper._5.brand-img4:hover {
    background-size: contain;
  }
  .img-wrapper.brand-img6:hover {
    background-size: contain;
  }
  .body-2 {
    padding-top: 259px;
  }
  .body-3 {
    padding-top: 260px;
  }
  .about-image._1 {
    margin-bottom: 10px;
  }
  .about-image._2 {
    margin-bottom: 10px;
  }
  .about-text {
    padding-left: 0%;
  }
  .about-text.left {
    padding-right: 0%;
  }
  .nav-link-3.w--current {
    display: inline-block;
  }
  .nav-link-3.mid {
    padding-top: 10px;
  }
  .mini-slider-img-2 {
    width: 24%;
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .rightarrow-2 {
    position: absolute;
  }
  .heading-36 {
    text-align: left;
  }
  .accreditations-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    text-align: center;
  }
  .link-block-2 {
    padding-left: 30px;
  }
  .link-block-3 {
    padding-left: 30px;
  }
  .heading-45 {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .column-14 {
    clear: none;
  }
  .columns-7 {
    clear: none;
  }
  .column-15 {
    clear: left;
  }
  .column-16 {
    clear: both;
  }
  .column-17 {
    clear: none;
  }
  .image-23 {
    float: left;
    text-align: center;
  }
  .body-5 {
    padding-top: 261px;
  }
  .image-24 {
    display: none;
  }
  .columns-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .columns-9 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 38px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    margin-top: 20px;
  }
  a.w--current {
    float: none;
  }
  .nav {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    text-align: left;
  }
  .nav-link {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #222;
  }
  .nav-link:hover {
    color: #7fc6d0;
  }
  .nav-link.w--current {
    display: block;
    color: #fff;
  }
  .nav-link.nav-dropdown {
    text-align: left;
  }
  .nav-link.nav-dropdown.w--open {
    color: #fff;
  }
  .nav-link.top {
    display: inline-block;
    text-align: center;
  }
  .nav-link.top.pull-right {
    float: none;
    text-align: center;
  }
  .nav-link.top.pull-right.show-mobile {
    display: inline-block;
    padding-top: 9px;
  }
  .nav-link._2 {
    padding-top: 17px;
    padding-bottom: 25px;
    font-size: 14px;
  }
  .nav-link.account-button {
    display: inline-block;
    margin-top: 15px;
    text-align: center;
  }
  .nav-link.phone {
    margin-top: 5px;
    color: #222;
  }
  .nav-menu {
    float: none;
    background-color: rgba(68, 68, 68, 0.9);
  }
  .nav-menu.top {
    position: static;
    display: block;
    margin-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
    opacity: 1;
  }
  .login-btn {
    top: 0px;
  }
  .slider {
    max-height: 500px;
    min-height: 300px;
  }
  .section {
    overflow: hidden;
    padding: 20px 10px;
  }
  .section.cta {
    text-align: center;
  }
  .section.blog {
    padding-right: 10px;
    padding-left: 10px;
  }
  .section.products {
    padding-top: 20px;
    background-color: #eee;
  }
  .section.grey {
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .section.collection-description {
    padding-bottom: 0px;
  }
  .mid-footer {
    padding: 20px 10px 40px;
  }
  .pull-right {
    float: right;
  }
  .brand {
    max-width: 200px;
    padding-left: 0px;
    text-align: center;
  }
  .brand.w--current {
    width: auto;
    max-width: 200px;
  }
  .pull-left.menu-btn {
    padding: 18px 19px 19px;
  }
  .pull-left.menu-btn.w--open {
    background-color: #dedfde;
  }
  .banner-text-small {
    font-size: 16px;
    line-height: 24px;
  }
  .banner-text-large {
    font-size: 100px;
    line-height: 100px;
    letter-spacing: 6px;
  }
  .mini-slider {
    height: auto;
  }
  .mini-slider-img {
    width: 33.33%;
  }
  .form {
    padding-right: 0px;
  }
  .form-col {
    padding-right: 0px;
  }
  .input {
    width: 100%;
  }
  .list-item {
    float: left;
  }
  .blogpost-wrapper {
    display: block;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .blog-element-wrapper {
    padding-right: 30px;
    padding-left: 0px;
  }
  .blog-social-wrapper {
    margin-top: 10px;
  }
  .disquis {
    margin-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .about-content-wrapper {
    display: block;
    max-width: 320px;
    margin: 20px auto;
  }
  .productwrapper {
    display: block;
    max-width: 300px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }
  .dropdownlist.w--open {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-left: 0px;
  }
  .hidearrow {
    display: none;
  }
  .header-fields {
    display: none;
    width: 100%;
    opacity: 0;
  }
  .nav-item {
    display: none;
  }
  .footerelementwrapper {
    padding-right: 0px;
  }
  .bottomfooter {
    padding-right: 20px;
    padding-left: 20px;
  }
  .google-translate {
    display: none;
  }
  .menu-button {
    display: inline-block;
    margin-top: 12px;
    margin-right: 20px;
    margin-bottom: 12px;
    padding: 12px;
    float: right;
    border: 1px solid #ddd;
    color: #aaa;
  }
  .menu-button.w--open {
    border-color: transparent;
    background-color: #666;
    color: #fff;
  }
  .m-filter-wrapper {
    display: none;
    opacity: 0;
  }
  .social-section-footer.top {
    display: none;
  }
  .nav-right {
    padding-right: 20px;
    float: right;
  }
  .banner-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .nav-container {
    text-align: center;
  }
  .searchform-wrapper {
    position: relative;
    display: block;
    max-width: 100%;
    padding-top: 0px;
    clear: both;
  }
  .searchform-close {
    margin-top: -7px;
  }
  .topnav-left {
    display: inline;
    float: none;
  }
  .flex-col {
    min-width: 100%;
  }
  .flex-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .title-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slider-button {
    margin-top: 15px;
  }
  .flex-col-copy {
    padding: 50px 20px;
  }
  .banner-text-medium {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 3px;
  }
  .top-footer {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .category-image:hover {
    opacity: 1;
  }
  .slider-container {
    top: 50%;
    bottom: auto;
    padding: 0px 20px;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    text-align: center;
  }
  .newsletter-column {
    margin-top: -20px;
    margin-bottom: 30px;
  }
  .mega-list.w--open {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-left: 0px;
  }
  .row {
    text-align: center;
  }
  .team-content-wrapper {
    display: block;
    max-width: 320px;
    margin: 20px auto;
  }
  .blogpost-caption {
    position: relative;
  }
  .blogpost-text {
    position: relative;
  }
  .blogpost-hover {
    position: relative;
    background-color: rgba(17, 17, 17, 0.9);
  }
  .product-code {
    margin-bottom: 10px;
    color: #777;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
  }
  .social-group-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .social-feed-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .social-col {
    margin-bottom: 20px;
  }
  .testimonial-slider {
    margin-bottom: 30px;
  }
  .testimonial-slide {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 19px;
    line-height: 27px;
  }
  .testimonial-slider-nav {
    font-size: 11px;
  }
  .testimonial-arrow-right {
    display: none;
  }
  .testimonial-arrow-left {
    display: none;
  }
  .footer-usps {
    padding-top: 35px;
    padding-bottom: 25px;
  }
  .usp1 {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .banner-usp {
    padding-left: 58px;
  }
  .banner-cta-icon {
    left: 20px;
  }
  .flex-child {
    display: block;
    width: auto;
    margin-right: 20px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
  .flex-child.small {
    width: auto;
    margin-bottom: 30px;
  }
  .img-overlay {
    display: block;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 66%;
  }
  .center-flex {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding-top: 20px;
    padding-bottom: 30px;
    background-image: none;
    color: #222;
    text-align: center;
    text-shadow: none;
  }
  .center-flex._1 {
    position: relative;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    text-align: center;
  }
  .center-flex._3 {
    top: 0%;
    -webkit-transform: translate(0px, 0%);
    -ms-transform: translate(0px, 0%);
    transform: translate(0px, 0%);
    text-align: center;
  }
  .center-flex._4 {
    text-align: center;
    text-shadow: none;
  }
  .elementwrapper {
    background-color: #fff;
  }
  .brandsection {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .home-element-wrapper {
    width: 100%;
    margin-bottom: 25px;
  }
  .div-block-6.white.left {
    padding-right: 20px;
  }
  .div-block-6.white.right {
    padding-right: 20px;
  }
  .icon-wrapper {
    margin-top: 20px;
    float: left;
    text-align: left;
  }
  .icon-wrapper._2 {
    margin-top: 20px;
  }
  .newsletter-section-2 {
    padding-bottom: 20px;
  }
  .login-btn-2 {
    top: 0px;
  }
  .nav-link-2 {
    padding-top: 20px;
    padding-bottom: 20px;
    color: hsla(0, 0%, 100%, 0.6);
  }
  .nav-link-2:hover {
    color: #fff;
  }
  .nav-link-2.w--current {
    display: block;
    color: #fff;
  }
  .nav-link-2.mid {
    display: inline-block;
    text-align: center;
  }
  .heading-5.white {
    margin-bottom: 10px;
  }
  .top-bar-text {
    font-size: 12px;
  }
  .text-slider {
    height: auto;
  }
  .slider-img {
    width: 125px;
  }
  .left-arrow {
    left: -10px;
  }
  .slider-title {
    font-size: 29px;
  }
  .slider-title.description {
    font-size: 17px;
  }
  .text-slider-right {
    padding-top: 5px;
    padding-right: 30px;
  }
  .right-arrow {
    right: -10px;
  }
  .text-slider-left {
    margin-bottom: 5px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .text-slider-row {
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
  }
  .body-2 {
    padding-top: 269px;
  }
  .body-3 {
    padding-top: 270px;
  }
  .about-text {
    padding-left: 0%;
  }
  .nav-link-3 {
    padding-top: 12px;
    padding-bottom: 12px;
    color: hsla(0, 0%, 100%, 0.6);
  }
  .nav-link-3:hover {
    color: #fff;
  }
  .nav-link-3.w--current {
    display: block;
    color: #fff;
  }
  .nav-link-3.mid {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 0px;
    float: left;
    text-align: center;
  }
  .mini-slider-img-2 {
    width: 32%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .mini-slider-2 {
    height: auto;
  }
  .flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .logo-img {
    height: auto;
    margin-top: 16px;
    padding-top: 0px;
  }
  .logo-img.logo {
    width: auto;
  }
  .link-block-2 {
    max-width: 150px;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .link-block-3 {
    max-width: 150px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .body-5 {
    padding-top: 269px;
  }
  .image-24 {
    display: none;
  }
}

@media (max-width: 479px) {
  h1 {
    margin-left: 0px;
  }
  .nav.top {
    text-align: center;
  }
  .nav-link {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 21px;
    font-size: 14px;
    line-height: 20px;
  }
  .nav-link.top {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 12px;
  }
  .nav-link._2 {
    width: 44px;
    padding-top: 17px;
    padding-bottom: 20px;
  }
  .nav-link.account-button {
    margin-top: 0px;
    margin-right: 0px;
    float: right;
    border-style: none;
    font-size: 12px;
  }
  .nav-link.account-button.last._2 {
    margin-right: 0px;
    padding-left: 0px;
    float: right;
  }
  .nav-link.phone {
    display: none;
    padding-left: 0px;
    float: left;
  }
  .nav-menu.top {
    padding-right: 5px;
    padding-left: 5px;
  }
  .section {
    padding-bottom: 0px;
  }
  .section.products {
    padding-top: 20px;
  }
  .section.grey {
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .mid-footer {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .brand {
    margin-right: auto;
    padding-left: 0px;
  }
  .brand.w--current {
    max-width: 120px;
  }
  .dropdown-link {
    font-size: 14px;
  }
  .banner-text-large {
    font-size: 70px;
    line-height: 70px;
  }
  .arrow.miniarrow.right-arrow {
    display: block;
  }
  .arrow.miniarrow.arrow-left {
    display: block;
  }
  .mini-slider-img {
    width: 100%;
    text-align: center;
  }
  .map {
    position: relative;
  }
  .list-item {
    width: 100%;
  }
  .blog-social-wrapper {
    margin-top: 10px;
  }
  .blog-social {
    margin-right: -14px;
  }
  .about-content-wrapper {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .header-fields {
    float: none;
  }
  .nav-item {
    display: none;
    float: none;
  }
  .menu-button {
    margin-right: 10px;
    padding: 5px;
    font-size: 20px;
  }
  .social-section-footer.top {
    display: none;
  }
  .nav-right {
    padding-right: 10px;
  }
  .searchform-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .top-right {
    padding-top: 5px;
    float: none;
  }
  .topnav-left {
    display: inline;
    float: none;
  }
  .slider-button {
    float: none;
  }
  .slider-nav {
    bottom: 0px;
  }
  .slider-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .team-content-wrapper {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .social-feed-wrapper {
    max-width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .testimonial-slide {
    padding-right: 10px;
    padding-left: 10px;
  }
  .testimonial-slider-nav {
    bottom: -40px;
  }
  .usp1 {
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .div-block-2 {
    font-size: 18px;
    line-height: 26px;
  }
  .center-flex {
    padding-right: 10px;
    padding-left: 10px;
  }
  .brandsection.call-to-action-section {
    padding-right: 10px;
    padding-left: 10px;
  }
  .hide-mobile {
    display: none;
  }
  .div-block-6.white.left {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  .div-block-6.white.right {
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .nav-link-2 {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 21px;
  }
  .nav-link-2.mid {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 13px;
  }
  .slider-img {
    width: 90px;
    margin-bottom: 5px;
  }
  .left-arrow {
    display: none;
  }
  .slider-title {
    font-size: 25px;
    line-height: 27px;
  }
  .text-slider-right {
    padding-right: 0px;
    padding-left: 10px;
  }
  .right-arrow {
    display: none;
  }
  .text-slider-row {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-6 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-7 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .column-8 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .body-2 {
    padding-top: 193px;
  }
  .body-3 {
    padding-top: 191px;
  }
  .about-text {
    padding-left: 0%;
  }
  .nav-link-3 {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 21px;
    font-size: 14px;
    line-height: 20px;
  }
  .nav-link-3.mid {
    padding: 10px 6px;
    font-size: 12px;
  }
  .mini-slider-img-2 {
    width: 45%;
    padding-right: 20px;
    text-align: center;
  }
  .logo-img {
    display: none;
  }
  .logo-img.logo {
    display: none;
    float: none;
  }
  .link-block-2 {
    max-width: 100px;
  }
  .link-block-3 {
    max-width: 100px;
  }
  .body-5 {
    padding-top: 193px;
  }
  .image-24 {
    display: block;
    max-width: 50%;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    float: left;
  }
  .body-6 {
    padding-top: 192px;
  }
  .body-7 {
    padding-top: 192px;
  }
}

@font-face {
  font-family: 'Social media';
  src: url('../fonts/social-media.woff2') format('woff2'), url('../fonts/social-media.eot') format('embedded-opentype'), url('../fonts/social-media.woff') format('woff'), url('../fonts/social-media.ttf') format('truetype'), url('../fonts/social-media.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Corin design';
  src: url('../fonts/corin-design.woff2') format('woff2'), url('../fonts/corin-design.eot') format('embedded-opentype'), url('../fonts/corin-design.woff') format('woff'), url('../fonts/corin-design.ttf') format('truetype'), url('../fonts/corin-design.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Rhesmanisa demo';
  src: url('../fonts/Rhesmanisa-Demo.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Rhesmanisa';
  src: url('../fonts/Rhesmanisa-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Rhesmanisa';
  src: url('../fonts/rhesmanisa-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}