html, body {
    overflow-x: hidden;
}

.testimonial-name p {
    font-weight: 400;
}

.pf-btn.pf-btn-primary, .pf-btn.pf-btn-secondary, .pf-btn.pf-btn-default {
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    font-size: 12px !important;
    line-height: 21px;
    letter-spacing: 1px;
    color: #666;
    border-radius: 0px;
}

.pf-btn.pf-btn-primary, .pf-btn.pf-btn-secondary {
    border: 1px solid #666;
}

.pf-btn.pf-btn-default {
    border: 1px solid #999;
}

.pf-btn.pf-btn-secondary:hover {
    background-color: #7fc6d0;
    border-color: #7fc6d0;
    color: #fff;
}

.pf-btn.pf-btn-default:hover {
    background-color: #999;
    color: #fff;
}

.login-section h1, .create-account h1, .sign-in-panel h1, .guest-checkout-panel h1,
.product-list-head h1, .pf-product-name, .pf-product-details-heading,
.pf-product-details-sub-heading, .pf-terms-wrapper h4 {
    font-family: Quicksand,sans-serif;
    color: #646464;
}

.pf-new-product-tag, .pf-sale-product-tag {
    font-family: Quicksand,sans-serif;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.pf-new-product-tag {
    background: #7fc6d0;
}
.pf-sale-product-tag {
    background: #ca2121;
}

.w-list-unstyled li {
    list-style: none;
}

.html-embed-2.w-embed.w-iframe {
    height: 500px;
}

.pf-btn.pf-btn-with-qty {
    margin-top: 0px;
}

.pageno {
    background-color: transparent;
    color: #666;
}

.pf-brand-info {
    display: none;
}

.nav-no-padding {
    padding-top: 5px;
    padding-bottom: 5px;
}

.logo-img.logo-small {
    height: 70px;
    margin-top: 5px;
}

.search-shrink {
    margin-top: 15px;
}

.link-shrink,
.nav-link.account-button.link-shrink {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.nav-link.phone.link-shrink {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 2px;
    padding-bottom: 2px;
}

.new.pf-new-product-tag {
    color: white;
}

.margin-top {
    margin-top: 10px !important;
}
/*Blas's code*/
.top-right .nav-link.account-button svg {
    color: #7fc6d0;
}

.topheader {
    background-color: #fff;
}

.description.p {
    margin-bottom: 10px;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 27px 10px;
    border: 1px solid transparent;
    background-color: #d07fa2;
    box-shadow: none;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    text-align: center;
    text-shadow: none;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: Quicksand,sans-serif;
}

    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: #fff;
        background-color: transparent;
        color: #fff;
    }

.button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid #222;
    background-color: transparent;
    box-shadow: none;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    color: #565656;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    font-family: Quicksand,sans-serif;
}

    .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: #111;
        background-color: #565656;
        color: #fff;
        text-decoration: none;
    }

.slider-button p {
    margin-bottom: 0px;
}

.title-wrapper h3 {
    color: #646464;
}

.heading-5.white h3 {
    padding-top: 0;
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    text-align: left;
    letter-spacing: 1px;
    text-transform: none;
    font-weight: 400;
    line-height: 26px;
    font-family: Quicksand,sans-serif;
}

.paragraph-5._2._1 p {
    color: #646464;
    display: inline-block;
    padding-right: 0;
    float: none;
    color: #646464;
    font-weight: 500;
    text-align: left;
    margin: 0px;
}

.banner-container.w-container h1 {
    margin-bottom: 12px;
    font-family: Rhesmanisa,sans-serif;
    font-size: 100px;
    line-height: 140px;
    font-weight: 400;
    letter-spacing: 6px;
    text-decoration: none;
    text-transform: none;
}
/*IE Explorer Fix*/
.banner-container.w-container {
    max-width: inherit;
}

.w-dropdown-link.w--current {
    color: #222;
}

.AboutList .AboutItem:nth-child(2n) .w-col.w-col-6 {
    float: right;
}

.AboutItem {
    margin-bottom: 40px;
}

.about-text {
    padding-left: 10px;
}

.blog-layout-boxed-featured .blogpost-wrapper .blogpost-caption {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    text-align: left;
    position: relative;
    background-color: transparent;
    bottom: 0;
    left: 0;
    right: 0;
    color: #222;
}

.blogposts-block .blogpost-text {
    text-align: left;
}

.blog-layout-boxed-featured .blogpost-row:hover {
    background-color: white !important;
}

.blog-layout-boxed-featured .blogpost-wrapper:hover {
    box-shadow: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.blog-element-wrapper {
    text-align: left;
}

    .blog-element-wrapper h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-family: Quicksand,sans-serif;
    }

.blog-layout-boxed-featured .blogpost-row {
    border-bottom: none !important;
}

.banner-text-large p {
    font-weight: 400;
    font-family: Rhesmanisa,sans-serif;
}

.pf-page-wrapper h1, #payment-success-page h1, #pf-sub-cats-listing h2, .pf-page-header h1 {
    font-family: Quicksand,sans-serif;
    color: #646464;
}

#create-account-form .checkbox-label {
    margin-right: 5px;
}

#discount-input-tbx {
    height: 40px;
}

#discount-btn {
    display: inline;
    font-size: 16px;
    top: 10px;
    padding: 9px 10px !important;
}

#pf-discount-input-wrapper {
    width: 35%;
    min-width: 300px;
}

#discount-input-tbx {
    height: 40px;
}

.pf-checkout-actions .tcLabel {
    margin-right: 5px;
}

.lg-outer, .lg-backdrop {
    z-index: 999999 !important;
}

.pf-product-main-image-wrapper:hover {
    cursor: zoom-in;
}

.pf-my-orders-table .pf-btn.pf-btn-xs {
    padding: 6px 6px;
}

.pf-btn.pf-btn-sm {
    padding: 6px 10px;
}

.pf-favs-wrapper .qty-btn {
    font-size: 14px !important;
}

#pf-product-list-page, #pf-product-details-page, #pf-product-listing, #pf-sub-cats-listing {
    padding-top: 0px;
    padding-bottom: 0px;
}

    #pf-product-details-page .pf-product-image-thumb {
        width: 30%;
    }

.breadcrumb {
    margin-bottom: 20px;
}

.pf-btn.pf-btn-colour-one:hover {
    background-color: #7fc6d0;
}

.pf-input.pf-hover-colour-one:focus, .pf-input.pf-hover-colour-one:hover, .pf-input.pf-hover-colour-two:focus, .pf-input.pf-hover-colour-two:hover {
    border-color: #6cb4ac;
}

.nav-link.account-button {
    margin-top: 16px;
}

.w-container.pf-container {
    margin-top: 20px;
}

.slider-container ul.mbss-textArea-btns {
    left: 170px;
}

.mini-slider-2 .addListItem2Btn, .mini-slider-2 .deleteListItem2Btn {
    bottom: 0px;
}

.mbss-subscribe-wrapper .mbss-edit-btn-subscribe {
    top: 55px;
}

.headerPhone ul.mbss-textArea-btns {
    left: -80px;
    top: 0px;
}

.imageWidget {
    height: 350px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.contact-bg .editImageX {
    top: 50px !important;
    left: 150px !important;
}

.nav-link.nav-dropdown {
    font-weight: 500;
}

#checkout-cart tbody {
    font-size: 16px;
}

.footerelementwrapper p {
    margin-bottom: 0px;
}

.slider-container.w-container {
    text-align: center;
}

.about-image.collection-image {
    background-image: none;
}

    .about-image.collection-image img {
        height: 100%;
    }

.container-wide.w-container.collection {
    max-width: 940px;
    width: unset;
}

.textHeight {
    margin-top: 10px;
}

.slider-button.flex-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .slider-button.flex-button:hover {
    background-color: #d07fa2;
}

.slider-container.w-container {
    max-width: inherit;
}

h2 {
    font-size: 26px;
    font-family: Quicksand,sans-serif;
    font-weight: 300;
}

.filter-subdropdown-list .filter-subdropdown-link {
    padding-left: 15px;
}

.productwrapper:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.dropdown:hover {
    border-style: unset;
    border-width: unset;
    border-color: unset;
}

.ui-autocomplete.ui-menu.ui-widget {
    z-index: 999999;
}

    .ui-autocomplete.ui-menu.ui-widget:hover {
        cursor: pointer;
    }

    .ui-autocomplete.ui-menu.ui-widget li.ui-menu-item:hover {
        background-color: #ddd;
    }

#scrollUp {
    right: 15px;
    bottom: 15px;
    display: block;
    padding-top: 2px;
    background-color: #666;
    transition: color .5s ease,background-color .5s ease;
    text-align: center;
    z-index: 9000;
    width: 44px;
    height: 44px;
    border-radius: 100%;
}

    #scrollUp:hover {
        background-color: #7fc6d0;
        color: #fff;
    }

.checkbox > label,
.w-checkbox > label {
    float: none;
    display: inline;
}

.link-3 a {
    color: #313030;
}

.cart-first img {
    max-width: unset;
}

#pf-popup-cart-wrapper .pf-continue-shopping-cart {
    float: left;
}

#pf-product-details-page .pf-product-details-variant-grid-wrapper {
    grid-template-columns: repeat(auto-fill, 129px);
}
.galleryCollection .elementwrapper {
cursor:zoom-in;
}
.brand-image-wrapper.imageWidget {
    max-height: 350px;
}

.pf-nav-filter-heading {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 5px;
    padding-top: 0;
    color: #646464;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.header {
    font-size: 15px;
}

.mega-list.w--open {
    overflow-y: auto;
    width: 1200px;
    padding: 10px;
    padding-top: 0;
}

body {
    padding-top: 206px;
}
.the-website.remodal-is-opened #header-top,
.the-website.remodal-is-opening #header-top {
    top: -206px;
}
.logo-img.logo {
    height: 100px;
}

.top-bar h3 {
    margin-top: 0;
    font-family: Quicksand,sans-serif;
    color: #646464;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
}
@media (max-width: 1199px) {
    .container-wide {
        width: 992px;
    }

    .mega-list.w--open {
        width: 992px;
    }
}

@media (max-width: 1024px) {
    .AboutItem img {
        height: 100%;
    }

    .about-image {
        height: unset;
    }
}

@media (max-width: 991px) {
    body {
        padding-top: 262px;
    }
    .the-website.remodal-is-opened #header-top,
    .the-website.remodal-is-opening #header-top {
        top: -262px;
    }

    /*.container-wide.w-container {
        max-width: 100%;
    }*/

    .galleryImage {
        text-align: center;
    }

    .header-container.container-wide.w-container {
        max-width: 100%;
    }

    .search-shrink {
        margin-top: 5px;
    }

    .mega-list.w--open {
        width: auto;
        max-width: unset;
    }
}

@media (max-width: 769px) {
    .pf-addresses-wrapper .w-col-6 {
        width: 100%;
    }

    .pf-my-orders-table .pf-btn.pf-btn-xs.pf-btn-primary {
        font-size: 10px !important;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .pf-quick-order-cart-footer {
        margin-right: 10px;
    }

    #edit-details-btn, #cancel-details-btn, #save-address-btn {
        margin-left: 10px;
    }

    .blog-categories h2, .tag-container h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .dropdownlink.w-dropdown-link {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    body {
        padding-top: 240px;
    }
    .the-website.remodal-is-opened #header-top,
    .the-website.remodal-is-opening #header-top {
        top: -240px;
    }

    .hidemobile {
        display: none;
    }

    .brand {
        max-width: 100%;
    }

    .logo-img.logo {
        height: 80px;
        margin-top: 8px;
    }
    .top-bar h3 {
        margin-top: 20px;
    }
    .nav-link.w--current {
        color: #222;
        text-align: initial;
    }

    .nav-menu .nav-link {
        font-size: 13px;
    }

    .nav-link.nav-dropdown.w--open {
        color: #222;
    }

    .AboutItem {
        text-align: center;
        margin-bottom: 25px;
    }

    .about-text {
        margin-top: 20px;
    }

    .m-filter-wrapper {
        display: block;
        opacity: 1;
    }

    .pf-tab-header {
        width: 100% !important;
        text-align: center;
    }

    .w-nav-overlay, #top-cart-holder, .topheader {
        overflow-y: auto;
    }

    .banner.image1 .banner-container h1 {
        font-size: 50px;
        line-height: 70px;
    }

    .columns-5 .w-col.w-col-6 {
        margin-bottom: 10px;
    }

    .nav-link {
        padding: 13px 15px;
    }

    .nav-link.account-button {
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .bag-opener {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .link-shrink {
        margin-top: 0;
        margin-bottom: 0;
    }
    .nav-link.account-button.link-shrink {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .nav-no-padding {
        padding-top: 14px;
        padding-bottom: 14px;
    }

    .nav-link.phone {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 15px;
    }
    .nav-link.phone.link-shrink {
        padding-top: 0;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 479px) {
    .brand-image-wrapper.imageWidget {
        max-height: 240px;
    }
    #orders-tab-content {
        overflow-x: scroll;
    }

    .cart-table tr td:nth-child(1) {
        width: 40%;
        padding: 1px;
    }

    .bag-opener {
        float: right;
        padding: 5px 6px 6px 6px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .image-24 {
        margin-top: 10px;
        max-width: 42%;
        -webkit-transition: all .5s ease;
        transition: all .5s ease
    }
    
    .image-24.logo-small {
        margin-top: 0;
        height: unset;
    }

    .linkWrappers {
        display: inline-block;
        float: right;
    }
    
    .nav-link.account-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .link-shrink,
    .nav-link.account-button.link-shrink {
        margin-top: 0;
        margin-bottom: 0;
    }

    .back-top-top {
        display: none;
    }

    .div-block-6.white.left {
        padding-bottom: 10px;
    }
.wishlist-cross-width-fix {
    width:15px !important;
}

#pf-product-details-page .pf-product-image-thumb {
    padding: 0px !important;
}

    .icon-wrapper {
        float: none;
    }

    .icon-text {
        text-align: center;
    }

    .nav {
        border-top: none;
    }

    .search-shrink {
        margin-top: 0px;
    }

    body {
        padding-top: 195px;
    }
    .the-website.remodal-is-opened #header-top,
    .the-website.remodal-is-opening #header-top {
        top: -195px;
    }

    .pf-my-orders-table .pf-btn.pf-btn-xs.pf-btn-primary {
        margin-top: 0px;
        margin-bottom: 5px;
        line-height: 10px;
        font-size: 9px !important;
    }

    .banner-container.w-container h1 {
        font-size: 75px;
        line-height: 100px;
    }
}

@media (max-width: 502px) {
    body {
        padding-top: 194px;
    }
    .the-website.remodal-is-opened #header-top,
    .the-website.remodal-is-opening #header-top {
        top: -194px;
    }
    .banner-container.w-container h1
    {
        font-size:48px;
    }
}
@media (max-width: 420px) {
    .login-form .pf-normal-link {
        float: left;
    }

    .login-form .pf-btn-primary {
        display: block;
    }
}

@media (max-width: 380px) {
    .pf-voucher-input {
        width: 50%;
        float: left;
    }

    #discount-btn {
        left: -34px;
    }

    .has-variants.pf-btn.pf-btn-sm.pf-btn-primary {
        height: 54px;
    }
}

.contact-bg {
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
}
.imageWidget, .logo-link {
    background-position: 0 0;
    background-size:100% auto;
}
.collectionGallery .lg-inner {
    background-color: #fff;
}
.collectionGallery .lg-actions .lg-next, .collectionGallery .lg-actions .lg-prev, .collectionGallery .lg-sub-html, .collectionGallery .lg-toolbar {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1080;
    color: #4a4747;
}
    .collectionGallery .lg-toolbar .lg-icon, .collectionGallery #lg-counter {
        color: #4a4747;
    }
.collectionGallery.lg-outer .lg-thumb-outer, .collectionGallery.lg-outer .lg-toogle-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    color:#4a4747;
}
@media (max-width: 1250px) {
    .brand-image-wrapper.imageWidget {
        max-height: 200px;
    }
}

.w-dropdown-link{
    padding: 10px 5px;
}
.full-size-banner{
    position:relative;
}
.full-size-banner .banner-container{
    position:absolute;
    top:50%;
    text-align:center;
}
.full-size-banner .pf-full-size-img{
    min-height:200px;
}
.dropdown-list.w--open {
    padding: 10px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
}
.w-dropdown-link.dropdown-link {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #646464;
    text-transform: none;
}
    .w-dropdown-link.dropdown-link:hover {
        background-color: #7fc6d0;
        color: #fff;
    }
.accreditations-wrapper .flex-child{
    width:auto;
    margin-left:0;
}
.img-wrapper._3._4.brand-img {
    background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_600,c_fill/Seagems/PageImages/BrandImage1.jpg);
    background-size: cover;
}
    .img-wrapper._3._4.brand-img:hover {
        background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_600,c_pad/Seagems/PageImages/BrandImage1Hover.png);
        background-size: cover;
    }
.img-wrapper._1.brand-img2 {
    background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_800,c_fill/Seagems/PageImages/BrandImage2.jpg);
    background-size: cover;
}
    .img-wrapper._1.brand-img2:hover {
        background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_800,c_pad/Seagems/PageImages/BrandImage2Hover.png);
        background-size: cover;
    }
.img-wrapper._3._2.brand-img3 {
    background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_600,c_fill/Seagems/PageImages/BrandImage3.jpg);
    background-size: cover;
}
    .img-wrapper._3._2.brand-img3:hover {
        background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_600,c_pad/Seagems/PageImages/BrandImage3Hover.jpg);
        background-size: cover;
    }
.img-wrapper._5.brand-img4 {
    background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_800,c_fill/Seagems/PageImages/BrandImage4.jpg);
    background-size: cover;
}
    .img-wrapper._5.brand-img4:hover {
        background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_800,c_pad/Seagems/PageImages/BrandImage4Hover.png);
        background-size: cover;
    }
.img-wrapper._3.brand-img5 {
    background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_600,c_fill/Seagems/PageImages/BrandImage5.jpg);
    background-size: cover;
}
    .img-wrapper._3.brand-img5:hover {
        background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_600,c_pad/Seagems/PageImages/BrandImage5Hover.jpg);
        background-size: cover;
    }
.img-wrapper.brand-img6 {
    background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_800,c_fill/Seagems/PageImages/BrandImage6.jpg);
    background-size: cover;
}
    .img-wrapper.brand-img6:hover {
        background-image: url(//res.cloudinary.com/ecomsilver/image/upload/w_1400,h_800,c_pad/Seagems/PageImages/BrandImage6Hover.gif);
        background-size: cover;
    }
.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .slider-button, .slider-button.flex-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .slider-button.flex-button:hover {
    background-color: #7fc6d0;
}
.button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
color:#fff;
border:none;
}
.logo-img.logo-small {
    height: 72px;
}
.slider-button.cta {
    background-color: #d4c970;
    margin-top: 0px;
    border: 1px solid #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 3px;
}
    .slider-button.cta a {
    text-decoration:none;
    }
.logo-img.logo {
margin-top:5px;
}
.logo-img.logo img {
    width: auto;
    max-height: 100%;
}
.slider-button p {
    font-weight:500;
}
.homepage-form {
margin-left:auto;
margin-right:auto;
text-align:center;
max-width:750px;
}
.register-container .heading-5 {
    text-align:center;
    margin-top:10px;
}
.nav-link.phone.w-nav-link {
padding-right:15px;
}
.title-wrapper.brands {
    margin-bottom:10px;
}
.homelogin p{
    margin-top:10px;
}
.ui-menu-item {
color:#222!important;
}
.ui-menu-item.ui-state-focus {
    color: #7fc6d0!important;
}
.slider {
max-height:585px;
height:unset;
}
.banner-img {
min-height:350px;
}
.newsletter-section-2 .w-container {
margin-bottom:10px;
}
@media screen and (max-width: 1550px) {
.slider{
    min-height:unset;}
}
/*Fix spacing on iPad Pro landscape*/
@media screen and (max-width: 1370px) {
    .brand-image-wrapper.imageWidget {
        max-height:240px;
    }
}

.dropdown-list .pf-nav-filter-heading {
    margin-top: 0;
}

#sidenav {
    background-color: #fff;
}

.sidenav #sidenav-close-btn {
    color: black;
}

.product-image {
    min-width: 0;
}

.horizontal-scroll-wrapper {
    position: relative;
}
.horizontal-scroll-box {
    overflow-x: hidden;
}
.scroll-arrow-left, .scroll-arrow-right {
    height: 100%;
    width: 40px;
}

.pf-product-image-thumb-wrapper {
    width: 100%;
}

.filter-specs.pf-filter-dropdown-list {
    padding: 5px 0;
}

.filter-subdropdown-link.pf-filter-item {
    display: block;
    padding: 1px 0;
    font-size: 14px;
}

.pf-filter-item input {
    top: 5px;
    margin-left: 10px;
}

.filter-subdropdown-link.pf-filter-item label {
    cursor: pointer;
    float: none;
    padding: 1px 0;
}

.filter-subdropdown-link.pf-filter-item input.filter-selected + label {
    background: #7fc6d0;
    color: #fff;
    font-weight: 500;
}

.product-code, .pf-product-list-item .pf-product-code {
    color: #666;
    font-size: 16px;
    font-weight: 700;
}

.prod-code-label {
    display: none;
}

.pf-product-details-variant-grid {
    flex-direction: column;
}

.pf-product-list-item .wish-list-parent {
    margin-top: 15px;
    font-size: 15px;
}

.pf-product-list-item .pf-add-cart-wrapper {
    margin-top: 10px;
}

.pf-product-list-item .pf-add-cart-wrapper .pf-btn {
    margin-top: 0;
    margin-bottom: 0;
}

.icon-ring, .icon-info, .icon-text {
    vertical-align: middle;
    cursor: pointer;
    color: #333;
}

.icon-ring {
    display: inline-block;
    width: 25px;
    height: 25px;
    fill: #333;
    stroke: #333;
    stroke-width: 3px;
    margin-right: 5px;
}

.icon-info {
    font-size: 25px;
    margin-right: 5px;
}

.ring-sizes-table {
    border-collapse: collapse;
    width: 100%;
}
.ring-sizes-table tr {
    border: 1px solid #666;
}
.ring-sizes-table th,
.ring-sizes-table td {
    width: 33.333333%;
    padding: 2px 5px;
}

.mini-slider-2 {
    text-align: center;
}

.mini-slider-2 .mini-slider-img {
    text-align: center;
    padding: 30px 15px;
}

.mini-slider-2 .mbss-listItem2-image-wrapper {
    height: 100%;
}

.mini-slider-2 .mbss-listItem2-image {
    height: 100%;
    width: auto;
}

.gallery-description {
    margin-top: 10px;
}

.slider-container.mbss-editmode {
    position: absolute;
}

.remodal[data-remodal-id=sgBoxChainModal] b {
    color: #646464;
    font-weight: 500;
}

.remodal[data-remodal-id=sgBoxChainModal] h3 {
    margin-bottom: 15px;
}

.remodal[data-remodal-id=sgBoxChainModal] p {
    margin-bottom: 15px;
}

.remodal[data-remodal-id=sgBoxChainModal] {
    text-align: left;
}

.heading.w-dropdown-link {
    text-align: left;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px;
    padding-top: 10px;
}
.custom-checkbox-wrapper {
    padding:15px;
    background-color:#f1f1f1;
}
.custom-checkboxes-wrapper label{
    clear:both;
}
.pf-flip-second-image {
    display:none;
}
.footerelementwrapper a {
    color:#999;
}
.footerelementwrapper a:hover {
    color: #222;
    text-decoration:none;
}
.pf-wishlist-edit-name .pf-edit-wishlist{
    top:10px;
    height:34px;
}
.pf-product-component-select-wrapper.validation-error .pf-product-component-accordion {
    background-color: #DA4C5B;
}
.pf-component-select-text {
    color: #7fc6d0;
    font-weight:500;
}
.pf-component-select-text u.pf-dotted {
    border-bottom: 1px dashed #7fc6d0;
}
.product-detail-slider-arrow {
    color: #7fc6d0;
}
.sign-up-right-section {
    background-color: #e0ebed
}
.sign-up-section .pf-btn-primary {
    background-color: #7fc6d0;
    color: #fff;
}
.sign-up-section .pf-btn-primary:hover {
    background-color: #fff;
    color: #7fc6d0;
}
.sign-up-section.w-col {
    padding:15px;
}
.customer-info-wrapper img{
    height:100%;
}
.customer-info-wrapper .textHeight a, .customer-info-wrapper .textHeigh {
    color: #646464;
    font-weight: 600;
    min-height: 42px;
}
.customer-info-wrapper .w-col-medium-6 {
    padding:10px;
}
.customer-info-wrapper .sortableItems:nth-child(4n) .mbss-text ul.redactor-toolbar {
    left: -186px !important;
}
.pf-page-wrapper .errormessage span {
    color: #8B0000;
}
.errormessage-highlight.emailFormMessage {
    display: none;
}

.footer-membership-logo {
    width: auto;
    max-width: 90%;
}
    .footer-membership-logo img {
        max-height: 60px;
    }

@media screen and (max-width: 991px) {
    .nav-container {
        max-width: none;
    }

    .pf-product-main-image-wrapper {
        width: 60%;
    }
    .pf-product-image-thumb-wrapper {
        width: 40%;
    }
}
@media screen and (min-width: 767px) and (max-width: 825px) {
    .description-wrapper h3 {
        font-size:16px;
    }
}
@media screen and (max-width: 767px) {
    .menu-button {
        margin-top: 0px;
        margin-right: 15px;
        margin-bottom: 0;
        line-height: 1;
        font-size: 20px;
        padding: 8px 12px;
    }

    .image-24.w--current {
        float: left;
    }
    .customer-info-wrapper .w-col-medium-6 {
        width: 50%;
    }
}

@media screen and (max-width: 479px) {
    .menu-button {
        margin-right: 0;
    }
    .customer-info-wrapper .w-col-medium-6 {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    nav.w-nav-menu {
        font-size: 0;
        float: none;
        display: block;
    }

    nav.w-nav-menu > .w-dropdown > .nav-link {
        font-size: 11px;
        padding-left: 8px;
        padding-right: 18px;
    }

    nav.w-nav-menu > .nav-link {
        font-size: 11px;
        padding-left: 8px;
        padding-right: 8px;
    }

    nav.w-nav-menu > .w-dropdown > .nav-link > .dropdown-icon {
        margin-right: 5px;
        top: 1px;
    }

    nav.w-nav-menu > .w-dropdown > .w-dropdown-list {
        font-size: 14px;
    }
}

@media screen and (min-width: 992px) {
    nav.w-nav-menu > .w-dropdown > .nav-link {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 26px;
    }

    nav.w-nav-menu > .nav-link {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }

    nav.w-nav-menu > .w-dropdown > .nav-link > .dropdown-icon {
        margin-right: 10px;}
.mbss-flip-wrapper .mbss-flip-item {
min-height:400px;
}
.flip-image-wrapper {
margin:10px;
}
input::-ms-clear {
    display: none;
    }
}

@media screen and (min-width: 1200px) {
    nav.w-nav-menu > .w-dropdown > .nav-link {
        padding-left: 15px;
        padding-right: 32px;
    }

    nav.w-nav-menu > .nav-link {
        padding-left: 15px;
        padding-right: 15px;
    }

    nav.w-nav-menu > .w-dropdown > .nav-link > .dropdown-icon {
        margin-right: 15px;
    }
}
@media (min-width: 770px) {
    #pf-product-listing .results-filter-wrapper.pf-product-list-options {
        display: block;
    }
}
.account-row #form-fail-msg {
    color: #FF0000;
}